@import "reset-css";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body::-webkit-scrollbar {
  display: none;
}

::selection {
  // background-color: var(--main);
  background-color: var(--dark-green);
}
