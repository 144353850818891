.container.collection {
  margin-top: 100px;
  margin-bottom: 100px;
  overflow: hidden;
  p {
    margin: 10px 0;
  }

  .collection-items {
    display: flex;
    flex-direction: column;
    gap: 80px;
    margin-top: 20px;
  }
}
@media screen and (max-width: 500px) {
  .collection-items {
    gap: 50px;
  }
  .collection-head {
    text-align: center;
  }
}
