.detail {
  width: 100vw;
  min-height: 100vh;
  // background-color: var(--dark);
  background-color: var(--bright);
  position: relative;
  padding-bottom: 100px;
  .close {
    width: 100px;
    height: 100px;
    font-size: 60px;
    font-weight: 700;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--bright);
    transition: 0.8s;
    &:hover {
      scale: 1.1;
    }
  }

  .item-list {
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .line {
      color: var(--bright);
      font-weight: 600;
      margin-bottom: 20px;
      padding: 20px 0;
      border-bottom: 2px solid var(--bright);
    }
    .controller {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 40px;
      justify-content: center;
      padding-bottom: 20px;
      margin: 40px 0;
      border-bottom: 3px solid var(--dark);
    }
    .nav-button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 18px;
      color: var(--dark);
      font-weight: 900;
      &.active {
        color: var(--green);
        border-bottom: 2px solid var(--green);
        font-size: 18px;
      }
    }
    .sample-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 40px;
    }
    .sample {
      // width: 100px;
      // height: 100px;
      // background-color: var(--main);
      color: var(--dark);
      margin-bottom: 20px;
      overflow: hidden;
      width: calc((100vw - 240px) / 6);
    }
    .img-area {
      overflow: hidden;
      // aspect-ratio: 3/4;
      aspect-ratio: 2/1;
      display: flex;
      // transform: rotate(90deg);
      justify-content: center;
      align-items: center;
    }
    img {
      width: 100%;
      // height: 150%;
      cursor: pointer;
      object-fit: contain;
      transition: 0.8s;
    }
    img:hover {
      scale: 1.2;
    }
    .sample-name {
      font-weight: 700;
      // font-size: 20px;
      margin-top: 20px;
    }
    .sample-des {
      display: flex;
      margin-top: 10px;
      font-size: 14px;
      align-items: baseline;
      .des-head {
        // padding-left: 3px;
        font-weight: 700;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: 1100px) {
    .item-list {
      .sample {
        width: calc((100vw - 200px) / 5);
      }
    }
  }
  @media screen and (max-width: 890px) {
    .item-list {
      .sample {
        width: calc((100vw - 120px) / 3);
      }
      // .img-area {
      //   height: ;
      // }
    }
  }
  @media screen and (max-width: 500px) {
    .item-list {
      .controller {
        gap: 20px;
      }
      .sample {
        width: calc((100vw - 80px) / 2);
      }
      // .img-area {
      //   // height: 210px;
      // }
    }
  }
}
// ---------------MODAL Style------------------
.modal-area {
  // background-color: var(--main);
  display: flex;
  gap: 20px;
  position: relative;
}
.modal-img {
  box-sizing: border-box;
  width: 50%;
}
.des-area {
  display: flex;
  flex-direction: column;
  gap: 20px;
  // margin-left: 20px;
  position: fixed;
  left: 60%;
}
@media screen and (max-width: 1000px) {
  .modal-img {
    width: 70%;
  }
  .des-area {
    left: 68%;
    width: 26%;
    margin: 0;
  }
}
@media screen and (max-width: 890px) {
  .modal-img {
    width: 100%;
    height: 100%;
    // object-fit: cover;
  }
  .des-area {
    width: fit-content;
    left: 70px;
    top: 120px;
    background-color: var(--green);
  }
}
@media screen and (max-width: 587px) {
  .modal-area {
    flex-direction: column;
  }
  .des-area {
    background-color: transparent;
    width: 100%;
    position: static;
    margin: 0;
  }
}

@media screen and (max-width: 368px) {
  .des-head {
    display: none;
  }
}
