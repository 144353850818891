:root {
  --main: #c6b39d;
  --dark: #504538;
  --second: #b7a99a;
  --green: #9dbdaf;
  --dark-green: #69887b;
  --bright: #fff8f0;
  --gray: #dfe0df;
}
body {
  font-family: "Noto Sans KR", "Poppins", sans-serif;
  font-optical-sizing: auto;
  color: var(--dark);
  // background-color: var(--bright);
  .en {
    font-family: "Poppins", sans-serif;
  }
  .dis {
    font-family: "Playfair Display", serif;
    font-optical-sizing: auto;
  }
}
a {
  color: inherit;
  text-decoration: none;
}
h1 {
  font-size: 44px;
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 20px;
}
h4 {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
}
.headline {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
.semibold {
  font-weight: 500;
}
.container {
  max-width: 1100px;
  width: 100%;
  height: auto;
  margin: 0 auto;
}
.company-name {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: var(--green);
}
.hilight {
  font-family: "Poppins", sans-serif;
  color: var(--green);
  font-weight: 500;
}

@media screen and (max-width: 1200px) {
  .container {
    padding: 0 20px;
  }
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 12px;
  }
  @media screen and (max-width: 400px) {
    h1 {
      font-size: 36px;
    }
    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 16px;
    }
    h4 {
      font-size: 12px;
    }
    p {
      font-size: 12px;
    }
  }
}
