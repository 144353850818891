.footer-area {
  width: 100vw;
  background-color: var(--gray);
  //   color: var(--dark);
  color: rgb(131, 118, 104);
  padding: 80px 0 50px 0;
  .container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    .flex {
      display: flex;
      justify-content: center;
    }
    h4 {
      letter-spacing: 2px;
      font-weight: 400;
    }
  }
  .bold {
    font-weight: 600;
  }
  .copy {
    // text-align: end;
    font-size: 12px;
    margin-top: 60px;
  }
  .slash {
    margin: 0 10px;
  }
  @media screen and (max-width: 400px) {
    padding: 50px 0 25px 0;
    .flex {
      flex-direction: column;
      gap: 20px;
    }
    h4 {
      font-size: 10px;
    }
    .slash {
      display: none;
    }
    .copy {
      margin-top: 25px;
    }
  }
}
