.container.map {
  margin-top: 150px;
  margin-bottom: 50px;
  .head {
    h1 {
      margin-bottom: 50px;
    }
    h4 {
      margin-bottom: 10px;
    }
  }
  #map {
    width: 100%;
    height: 450px;
    .info-window {
      box-sizing: border-box;
      padding: 8px 5px;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .info-window-head {
      font-size: 16px;
      padding-bottom: 3px;
    }
    .info-window-add {
      font-size: 12px;
      padding-bottom: 3px;
    }
    .info-window-link {
      font-size: 14px;
      display: flex;
      gap: 5px;
      // justify-content: space-between;
    }
  }
}
