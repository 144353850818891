.btn {
  background-color: transparent;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  padding: 8px 15px;
  border-radius: 15px;
  border: 3px solid var(--dark);
  color: var(--dark);
  cursor: pointer;
  transition: 0.5s;
  width: 150px;
}
.btn.primary:hover {
  color: var(--dark-green);
  border-color: var(--dark-green);
  transition: 0.5s;
  text-decoration: underline;
}
.btn.secondary {
  color: var(--dark-green);
  border-color: var(--dark-green);
}
.btn.secondary:hover {
  color: var(--bright);
  border-color: var(--bright);
  // background-color: var(--dark-green);
  // background-color: var(--main);
  background-color: rgba(105, 136, 123, 0.6);
  //   opacity: 0.6;
  text-decoration: underline;
}
.btn.more {
  color: var(--bright);
  border-color: var(--bright);
  &:hover {
    background-color: var(--bright);
    color: var(--dark);
  }
}

@media screen and (max-width: 500px) {
  .btn {
    font-size: 14px;
    padding: 6px 10px;
  }
}
