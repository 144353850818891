.nav {
  position: fixed;
  width: 100vw;
  // padding: 10px 0;
  font-family: "Poppins", sans-serif;
  z-index: 100;
  transition: 0.8s;
  padding: 5px 0;
  &.view {
    background-color: var(--bright);
    // border-bottom: 1px solid #aaa;
    // box-shadow: 0px 1px 2px #aaa;
  }
  .container {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logo {
    font-weight: 700;
    transition: 0.5s;
    width: 150px;
  }
  .logo:hover {
    color: var(--second);
  }
  .nav-gnv {
    display: flex;
    gap: 20px;
    color: #999;
    .active {
      color: var(--dark);
    }
    h3 {
      font-weight: 500;
      padding: 3px 0;
    }
    h3:hover {
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 500px) {
    .logo {
      width: 100px;
    }
  }
}
